// $font-size-base: 0.8rem;
// $font-size-xs: 0.7rem;
// $danger: #dc3545;
// $light: #f8f9fa;
// $primary: #031627;
// $success: #28a745;

// /* Color Theme Swatches in Hex */
// $Brane-1-hex: #bfb417;
// $Brane-2-hex: #d9cc1e;
// $Brane-3-hex: #f2e529;
// $Brane-4-hex: #bfbbba;
// $Brane-5-hex: #0d0d0d;

// /* Color Theme Swatches in RGBA */
// // $Brane-1-rgba: rgba(191,180,22, 1);
// // $Brane-2-rgba: rgba(216,204,30, 1);
// // $Brane-3-rgba: rgba(242,228,41, 1);
// // $Brane-4-rgba: rgba(191,187,185, 1);
// // $Brane-5-rgba: rgba(12,12,12, 1);

// /* Color Theme Swatches in HSLA */
// // $Brane-1-hsla: hsla(56, 78, 42, 1);
// // $Brane-2-hsla: hsla(56, 75, 48, 1);
// // $Brane-3-hsla: hsla(56, 88, 55, 1);
// // $Brane-4-hsla: hsla(16, 4, 73, 1);
// // $Brane-5-hsla: hsla(0, 0, 5, 1);

// // Override default variables before the import
// $theme-colors: (
//   'danger': $danger,
//   'dark': #343a40,
//   'info': #17a2b8,
//   'light': $light,
//   'primary': $primary,
//   'secondary': $Brane-4-hex,
//   'success': $success,
//   'warning': #ff8c00
// );

// $utilities: (
//   'order': (responsive: true,
//     property: order,
//     values: (first: -1,
//       0: 0,
//       1: 1,
//       2: 2,
//       3: 3,
//       4: 4,
//       5: 5,
//       6: 6,
//       7: 7,
//       8: 8,
//       9: 9,
//       10: 10,
//       11: 11,
//       12: 12,
//       last: 13))
// );

// // Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html,
body {
  height: 100%;
  // background-color: #29425d;
  background-image: url('../src/assets/Meteor.svg');
}

// /* loader kachungwa */
// // .matches-ajax-loader-section {
// //   align-items: center;
// //   display: flex;
// //   flex-direction: row;
// //   justify-content: center;
// //   height: calc(100% - 80px);
// // }

// @keyframes ldio-ibgcpws5s9l {
//   0% {
//     top: 96px;
//     left: 96px;
//     width: 0;
//     height: 0;
//     opacity: 1;
//   }

//   100% {
//     top: 18px;
//     left: 18px;
//     width: 156px;
//     height: 156px;
//     opacity: 0;
//   }
// }

// .ldio-ibgcpws5s9l div {
//   position: absolute;
//   border-width: 4px;
//   border-style: solid;
//   opacity: 1;
//   border-radius: 50%;
//   animation: ldio-ibgcpws5s9l 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
// }

// .ldio-ibgcpws5s9l div:nth-child(1) {
//   border-color: #ff8c00;
// }

// .ldio-ibgcpws5s9l div:nth-child(2) {
//   border-color: var(--theme-blue);
//   animation-delay: -0.5s;
// }

// .loadingio-spinner-ripple-910yb32hdd {
//   width: 200px;
//   height: 200px;
//   display: inline-block;
//   overflow: hidden;
//   background: transparent;
// }

// .ldio-ibgcpws5s9l {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   transform: translateZ(0) scale(1);
//   backface-visibility: hidden;
//   transform-origin: 0 0;
// }

// .ldio-ibgcpws5s9l div {
//   box-sizing: content-box;
// }

// .fs-7 {
//   font-size: $font-size-xs !important;
// }

// // previous receipt styles
// .mobile-receipt {
//   background-color: var(--theme-blue);
//   // color: #ff8c00;

//   // make all buttons have similar radius
//   .btn,
//   .form-control {
//     border-radius: 0.125rem;
//   }

//   .bet-info {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);

//     .rounded-left {
//       border-top-left-radius: 0.125rem !important;
//       border-bottom-left-radius: 0.125rem !important;
//     }

//     .rounded-right {
//       border-top-right-radius: 0.125rem !important;
//       border-bottom-right-radius: 0.125rem !important;
//     }
//   }

//   .bet-summary {
//     display: grid;
//     grid-template-columns: 2fr 1fr;
//   }

//   .control-buttons {
//     display: grid;
//     grid-column-gap: 0.5rem;
//     grid-template-columns: repeat(2, 1fr);
//   }

//   .set-stake-form {
//     display: grid;
//     grid-column-gap: 0.5rem;
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

.app {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
}

.sidebar {
  width: 250px;
  background: #222;
  color: white;
  padding: 15px;
}

.matches-list {
  flex: 1;
  padding: 20px;
}

.bet-slip {
  width: 300px;
  background: #333;
  color: white;
  padding: 15px;
}
